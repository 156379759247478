
import { defuFn } from '/Users/chrisspiegl/Sites/Projects/test-ssr/node_modules/.pnpm/defu@6.1.2/node_modules/defu/dist/defu.mjs'

const inlineConfig = {
  "firebaseConfig": {
    "apiKey": "AIzaSyBJlJn3cSAZAEtiLOxWTRAQbvtW1eF0yfg",
    "authDomain": "chris-spiegl-testing.firebaseapp.com",
    "projectId": "chris-spiegl-testing",
    "storageBucket": "chris-spiegl-testing.appspot.com",
    "messagingSenderId": "831044756418",
    "appId": "1:831044756418:web:5e0a9aac680ed9b8a02c5b"
  },
  "vuefireOptions": {
    "optionsApiPlugin": false,
    "config": {
      "apiKey": "AIzaSyBJlJn3cSAZAEtiLOxWTRAQbvtW1eF0yfg",
      "authDomain": "chris-spiegl-testing.firebaseapp.com",
      "projectId": "chris-spiegl-testing",
      "storageBucket": "chris-spiegl-testing.appspot.com",
      "messagingSenderId": "831044756418",
      "appId": "1:831044756418:web:5e0a9aac680ed9b8a02c5b"
    }
  }
}



export default /* #__PURE__ */ defuFn(inlineConfig)
