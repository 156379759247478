import components_plugin_KR1HBZs4kY from "/Users/chrisspiegl/Sites/Projects/test-ssr/.nuxt/components.plugin.mjs";
import unhead_ybdzyfrqdf from "/Users/chrisspiegl/Sites/Projects/test-ssr/node_modules/.pnpm/nuxt@3.3.1_36lvh7uyik5cyyok7ph4zotgne/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import vueuse_head_polyfill_qEhwqUa92H from "/Users/chrisspiegl/Sites/Projects/test-ssr/node_modules/.pnpm/nuxt@3.3.1_36lvh7uyik5cyyok7ph4zotgne/node_modules/nuxt/dist/head/runtime/plugins/vueuse-head-polyfill.js";
import router_RfM70L7BDX from "/Users/chrisspiegl/Sites/Projects/test-ssr/node_modules/.pnpm/nuxt@3.3.1_36lvh7uyik5cyyok7ph4zotgne/node_modules/nuxt/dist/app/plugins/router.js";
import plugin_client_56oKRA8f2M from "/Users/chrisspiegl/Sites/Projects/test-ssr/node_modules/.pnpm/nuxt-vuefire@0.1.6_roopvj2ufxea6vpgrtqwyn5nai/node_modules/nuxt-vuefire/dist/runtime/app/plugin.client.mjs";
import templates_plugin_68130e5e_NQBSIOmQus from "/Users/chrisspiegl/Sites/Projects/test-ssr/.nuxt/templates.plugin.68130e5e.ejs";
import chunk_reload_client_28l353rLlZ from "/Users/chrisspiegl/Sites/Projects/test-ssr/node_modules/.pnpm/nuxt@3.3.1_36lvh7uyik5cyyok7ph4zotgne/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
export default [
  components_plugin_KR1HBZs4kY,
  unhead_ybdzyfrqdf,
  vueuse_head_polyfill_qEhwqUa92H,
  router_RfM70L7BDX,
  plugin_client_56oKRA8f2M,
  templates_plugin_68130e5e_NQBSIOmQus,
  chunk_reload_client_28l353rLlZ
]